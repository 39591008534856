import { FC, useMemo } from "react";
import { Select } from "antd";
import { DefaultOptionType, SelectProps } from "antd/lib/select";

import { ValueListItem, ValueListSlug } from "../queries/api/types";
import { useValueListItemList } from "../queries/valueListItems";
import { ValueListItemListPayload } from "../queries/api/valueListItems";

interface SelectValueListItemProps extends SelectProps {
    valueListSlug: ValueListSlug;
    valueListItemFieldsKey: string;
    additionalOptions?: DefaultOptionType[];
    disabledItems?: Array<ValueListItem["id"]>;
    queryPayload?: Omit<ValueListItemListPayload, "valueListSlug">;
}

const SelectValueListItem: FC<SelectValueListItemProps> = ({
    valueListSlug,
    valueListItemFieldsKey,
    queryPayload,
    additionalOptions = [],
    disabledItems = [],
    ...props
}) => {
    const { data: valueListItemsData } = useValueListItemList({
        valueListSlug,
        ...queryPayload,
    });

    const valueListItemsOptions = useMemo<DefaultOptionType[]>(() => {
        return (
            valueListItemsData?.items.map((item) => ({
                label: item.fields[valueListItemFieldsKey],
                value: item.id,
                disabled: disabledItems.includes(item.id),
            })) || []
        );
    }, [disabledItems, valueListItemFieldsKey, valueListItemsData?.items]);

    return <Select {...props} options={[...additionalOptions, ...valueListItemsOptions]} />;
};

export default SelectValueListItem;
